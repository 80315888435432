import React from "react"

const Button = ({buttonText, buttonColor, textColor, onClick, href}) => {
    return(
        <button className={`text-center text-sm p-2 font-medium bg-${buttonColor} text-${textColor}`}
            href={href}
            onClick={onClick}>
            {buttonText}
        </button>
    )
}

Button.defaultProps = {
    buttonColor: "rp-yellow",
    textColor: "rp-black"
};

export default Button