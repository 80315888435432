import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const Verticals = (props) => {
    const data = useStaticQuery(
        graphql`
        query{
            allVerticalsJson {
                  nodes {
                    title
                    description
                    image {
                      childImageSharp {
                          id,
                          fluid(maxWidth:500) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                          }
                      }
                    }
                  }
                }
            }
        `
    )

    return(
        <div id={props.id} className="grid md:grid-cols-3 md:px-4 grid-rows-1 md:py-8 bg-rp-black">
            <div className="md:col-span-1 col-span-3 mx-auto flex items-center">
                <h2 className="text-white text-center py-6 text-2xl md:text-3xl leading-tight font-medium">
                    Verticals
                </h2>
            </div>
            <div className="md:col-span-2 col-span-3">
                <div className="grid grid-cols-1 md:grid-cols-4 md:gap-4">
                    {data.allVerticalsJson.nodes.map(vertical =>(
                        <BackgroundImage
                            Tag="div"
                            fluid={[vertical.image.childImageSharp.fluid]}
                            id={vertical.image.childImageSharp.id}
                            role="img"
                            className="h-20 overflow-hidden md:rounded-md flex items-center justify-center"
                        >
                            <div className="absolute inset-0 z-0 bg-black bg-opacity-50"></div>
                            <h1 className="z-10 relative text-white text-center text-lg lg:text-xl leading-tight font-medium">{vertical.title}</h1>
                        </BackgroundImage>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Verticals