import React, {
  Children,
  cloneElement
} from "react";
import {
  useKeenSlider
} from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./styles/slider.css";


const Slider = (props) => {
  const arrowColor = props.arrowColor
  const [currentSlide, setCurrentSlide] = React.useState(0);
  // const [pause, setPause] = React.useState(false);
  const timer = React.useRef();
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    loop: true,
    duration: 2000
  });


  const slides = Children.map(
    props.children, child => cloneElement(child, {
      className: `${child.props.className ? `${child.props.className} ` : ''}keen-slider__slide`
    })
  );

  return ( <
    div className = "navigation-wrapper" >
    <
    div ref = {
      sliderRef
    }
    className = "keen-slider" > {
      slides
    } <
    /div> {
      slider && ( <
        ArrowLeft onClick = {
          e => e.stopPropagation() || slider.prev()
        }
        arrowColor = {
          arrowColor
        }
        />
      )
    } {
      slider && ( <
        ArrowRight onClick = {
          e => e.stopPropagation() || slider.next()
        }
        arrowColor = {
          arrowColor
        }
        />
      )
    } <
    /div>
  );
}

function ArrowLeft(props) {
  return ( <
    svg onClick = {
      props.onClick
    }
    className = {
      `fill-current text-${props.arrowColor} arrow arrow--left`
    }
    xmlns = "http://www.w3.org/2000/svg"
    viewBox = "0 0 24 24" >
    <
    path d = "M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" / >
    <
    /svg>
  );
}

function ArrowRight(props) {
  return ( <
    svg onClick = {
      props.onClick
    }
    className = {
      `fill-current text-${props.arrowColor} arrow arrow--right`
    }
    xmlns = "http://www.w3.org/2000/svg"
    viewBox = "0 0 24 24" >
    <
    path d = "M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" / >
    <
    /svg>
  );
}

export default Slider