import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "../components/slider"

const About = (props) => {
    const data = useStaticQuery(graphql`
    {
      allAboutJson {
        nodes {
          id
          name
          bio_1
          bio_2
          bio_3
          profile_image {
            childImageSharp {
              fluid(maxWidth: 500, maxHeight: 580) {
                ...GatsbyImageSharpFluid
                    }
                }
            }
          certifications {
              image{
                childImageSharp {
                    fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                        }
                    }
                }
            } 
        }
      }
    }
  `)

  const aboutSlides = data.allAboutJson.nodes.map((about) => (
    <div key={about.id} className="about-slide">
      <div className="grid md:grid-cols-2 items-center">
        <div className="sm:p-12">
          <Img 
            fluid={about.profile_image.childImageSharp.fluid}
            style={{minHeight:'100%', maxHeight:'580px', maxWidth:'500px', margin: '0 auto'}}
            imgStyle={{objectFit:'cover'}}
            className="shadow-2xl md:rounded-lg"
          />
        </div>
        <div className="container mx-auto p-10 lg:p-20 flex-col">
          <h2 className="text-rp-black text-center md:text-center text-2xl sm:text-4xl leading-tight font-semibold">
            {about.name}
          </h2>
          <p className="text-base xl:text-lg lg:text-md pt-5 text-justify">
            {about.bio_1}
          </p>
          <p className="text-base xl:text-lg lg:text-md pt-5 text-justify">
            {about.bio_2}
          </p>
          <p className="text-base xl:text-lg lg:text-md pt-5 text-justify">
            {about.bio_3}
          </p>
        </div>
        {about.certifications && about.certifications.length > 0 && (
          <div className="md:col-span-2">
            <div className="p-10 md:pt-4 lg:pt-4 certifications grid gap-12 grid-rows-4 md:grid-cols-4 md:grid-rows-1 ">
              {about.certifications.map((certification) => (
                <Img key={certification.id} fluid={certification.image.childImageSharp.fluid}
                  style={{height:'100px', padding: '5px'}}
                  imgStyle={{objectFit:'contain'}}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  ));

  return (
      <section id={props.id}>
        <h1 className="text-4xl text-center font-bold py-8">Our Team</h1>
        <Slider arrowColor="rp-grey">
          {aboutSlides}
        </Slider>
    </section>
  );
}

export default About;