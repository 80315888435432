import React from "react"
import logo from "../images/logo.svg"

import HamburgerMenu from "react-hamburger-menu"

const Nav = ({isMenuOpen, toggleMenu, menuAnimation}) => {
    return(
            <>
                <div className="grid grid-cols-3 pt-5">
                    <img className="col-start-2 col-end-3 mx-auto self-center h-20 md:h-32" src={logo} alt="Logo" />
                    <HamburgerMenu 
                    height={24} 
                    width={36} 
                    className="self-center mx-auto mr-10 z-20" 
                    isOpen={isMenuOpen} 
                    menuClicked={toggleMenu} 
                    color={ isMenuOpen ? '#ffffff' : '#000000' }
                    />
                </div>
            </>
    )
}

export default Nav;