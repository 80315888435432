import React from "react"
import Layout from "../components/layout"
import Hero from "../sections/hero"
import Services from "../sections/services"
import About from "../sections/about"
import Verticals from "../sections/verticals"
import Testimonials from "../sections/testimonials"
import Contact from "../sections/contact"


const Index = () => {
  return (
    <Layout>
        <Hero id="hero" />
        <Services id="services" />
        <About id="about" />
        <Verticals id="verticals" />
        <Testimonials id="testimonials" />
        <Contact id="contact" />
    </Layout>
  )
}

export default Index;
