import React from "react"

const Contact = (props) => {
    return(
    <>
        <div id={props.id} className="container mx-auto py-6 px-4 md:px-16 flex items-center justify-center">
            <div className="flex flex-col px-4">
                <h4 className="text-2xl md:text-3xl text-rp-black font-bold text-center md:text-left">
                To protect your most valuable property, reach out to
                </h4>
                <h5 className="text-base md:text-2xl text-rp-yellow leading-tight text-center font-medium md:text-left">
                    <a href="mailto:rujuta@rujutaphadkeip.com">rujuta@rujutaphadkeip.com</a>
                </h5>
            </div>
            <div className="text-right px-4 w-3/5 md:w-1/2">
                <p className="">
                    <span className="font-medium text-lg">Pune</span>
                    <br/>
                    202, Pallavi House, Opp. Kamla Nehru Park <br/>Deccan Gymkhana - 411004
                </p>
                <p className="mt-10">
                    <span className="font-medium text-lg">Mumbai</span>
                    <br/>
                    17/4, 60 Hill Road, Bandra (W) - 400050
                </p>
            </div>
        </div>
        <footer className="mx-auto text-xs text-gray-600 text-center py-1"> 
            {`© ${new Date().getFullYear()}, Rujuta Phadke Intellectual Property`}
        </footer>
    </>
    )
}

export default Contact