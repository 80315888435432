import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "../components/slider"
// import { OutboundLink } from "gatsby-plugin-google-analytics"


const Testimonials = (props) => {

  const data = useStaticQuery(graphql`
        query{
            allTestimonialsJson {
                nodes {
                    id
                    company_name
                    champion_name
                    quote
                    backlink
                    image {
                      childImageSharp {
                        fixed(width:96, height: 96) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                } 
            }
        }
    `)

  const content = data.allTestimonialsJson.nodes.map((t) => 
  <Testimonial key={t.id} 
  name={t.champion_name} 
  companyName={t.company_name}
  quote={t.quote}
  backlink={t.backlink}
  image={t.image.childImageSharp.fixed}
  />
  )

  return (
    <section id={props.id}>
        <Slider arrowColor="rp-grey">
            {content}
        </Slider>
    </section>
  );
}

const Testimonial = (props) => {
  return(
    <div className={`${props.className} bg-rp-yellow container mx-auto py-10 md:py-10 flex items-center justify-center`}>
      <div className="flex flex-col px-4">
        <Img 
          className="rounded-full mx-auto mb-4" 
          imgStyle={{objectFit:'contain'}}
          fixed={props.image}
        />
        <h4 className="text-base text-rp-black text-center">
          {props.name}
        </h4>
        <h5 className="text-sm text-rp-black leading-tight font-medium text-center underline">
          <a href={props.backlink} target="_blank">{props.companyName}</a>
        </h5>
      </div>
      <div className="text-xl text-center px-4 w-3/5 md:w-1/2">
        {props.quote}
      </div>
    </div>
  )
}

export default Testimonials