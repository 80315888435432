import React from "react"
import { useCookies } from "react-cookie"
import Modal from "react-modal"
import Button from "../components/button"
import "./styles/modal.css"

Modal.setAppElement('body')

const Disclaimer = () => {
    const [cookies, setCookie] = useCookies(['disclaimer_accepted']);
    const [isDisclaimerOpen, setDisclaimerOpen] = React.useState(
        !Boolean(cookies.disclaimer_accepted)
    );  

    const onAccept = () => {
        setCookie('disclaimer_accepted', true);
        setDisclaimerOpen(false);
    }

    const modalCustomStyles = {
        content : {
          top: '100px',
          bottom: '100px'
        }
    };

    return (
        <Modal isOpen={isDisclaimerOpen} style={modalCustomStyles}>
            <div className="modal-div flex flex-col mx-auto">
                <h1 className="text-2xl text-center font-semibold">Disclaimer</h1>
                <p className="p-4 text-sm md:text-base">As per the rules of the Bar Council of India, Rujuta Phadke Intellectual Property (the "Firm") is not permitted to advertise or solicit work as a law firm in India. 
                    By clicking on 'I Agree' the user expressly acknowledges having read and understood the following:</p>
                <ul className="py-4 px-8 container list-disc text-sm md:text-base">
                    <li>This website is not created with the purpose of advertising or soliciting for the Firm and there has been no advertising or solicitation of any sort by means of this website.</li>
                    <li>The purpose of this website is to provide information to the users about the Firm, its practice areas and professionals, only on his/her specific request. The contents of the website should not be construed as legal advice in any manner whatsoever.</li>
                    <li>Information received or transmitted from this website does not create any attorney client relationship.</li>
                    <li>In no event will the Firm be liable for any damage caused as a consequence of the data provided on this website.</li>
                    <li>The content provided on this website is the Intellectual Property of the Firm.</li>
                </ul>
                <div className="flex justify-center">
                    <Button buttonText="Agree" onClick={onAccept} />
                </div>
            </div>
        </Modal>
    )
}

export default Disclaimer