import React from "react"
import { graphql, useStaticQuery} from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Nav from "../components/nav.js"

const HeroContent = () => {
    return (
        <div className="container pt-24 md:pt-24 px-6 mx-auto flex-1 bg-no-repeat bg-left">
            <div className="flex flex-col w-full justify-center lg:items-start overflow-y-hidden">
                <h1 className="text-rp-black text-4xl md:text-7xl lg:text-8xl leading-tight">Comprehensive IP Management</h1>
                <h2 className="text-rp-black text-2xl md:text-3xl lg:text-4xl md:font-semibold leading-tight md:mt-1 mt-10 mb-40">Strategic. Customized. Value Driven.</h2>
            </div>
        </div>
    )
}

const Hero = (props) => {
    const { desktop } = useStaticQuery(
        graphql`
        query {
            desktop: file(relativePath: { eq: "background.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 4160) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
        `
      )

    const imageData = desktop.childImageSharp.fluid
        
    return(
            <BackgroundImage
                Tag="section"
                fluid={imageData}
                backgroundColor={`#ffffff`}
                title="RPIP"
                id={props.id}
                role="img"
                preserveStackingContext={true}
                className="flex flex-col lg:h-screen h-full"
            >
                <Nav isMenuOpen={props.isMenuOpen} toggleMenu={props.toggleMenu} menuAnimation={props.menuAnimation} />
                <HeroContent />
            </BackgroundImage>
    )
}

export default Hero;