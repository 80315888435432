import React from "react"
import { animated } from "react-spring"
import { Link } from "gatsby"

const MenuItem = ({ linkId, name, toggleMenu }) => {
    return(
        <Link className="text-4xl text-center text-rp-yellow py-4 hover:text-white active:text-white" onClick={toggleMenu} to={`/#${linkId}`}>{name}</Link>
    )
}

const Menu = ({style, toggleMenu}) => {
    return (  
            <animated.div style={style} className="fixed right-0 z-10 h-full opacity-0 w-screen md:w-1/3 lg:w-1/5 bg-rp-black flex">
                <nav className="mx-auto flex flex-col py-32 justify-center align-center">
                    <MenuItem  toggleMenu={toggleMenu} linkId="hero" name="Home" />
                    <MenuItem  toggleMenu={toggleMenu} linkId="services" name="Services" />
                    <MenuItem  toggleMenu={toggleMenu} linkId="about" name="Our Team" />
                    <MenuItem  toggleMenu={toggleMenu} linkId="verticals" name="Verticals" />
                    <MenuItem  toggleMenu={toggleMenu} linkId="testimonials" name="Testimonials" />
                    <MenuItem  toggleMenu={toggleMenu} linkId="contact" name="Contact" />
                </nav>
            </animated.div>
    )
} 

export default Menu