import React, { useState } from "react"
import { ArrowRight } from "react-feather"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';



const Services = (props) => {
    const [selected, setSelected] = useState(1);

    const data = useStaticQuery(graphql`
        query{
            allServicesJson {
                nodes {
                    id
                    title
                    description
                    image {
                        childImageSharp {
                          fluid(maxWidth: 700) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                    }
                } 
            }
        }
    `)


    
    const content = data.allServicesJson.nodes.map((service) => 
    <ServiceItem key={service.id} id={service.id} 
        title={service.title} description={service.description} 
    />
    )

    return(
            <section id={props.id} className="lg:h-screen h-full grid md:grid-cols-2 grid-rows-1">
                <div className="bg-rp-yellow relative">
                    <ul className="lg:absolute lg:transform lg:-translate-y-1/2 lg:top-1/2 container mx-auto p-10 py-12 lg:p-20 flex-col">
                        <Accordion onChange={(item_ids) => setSelected(item_ids[0])}
                        preExpanded={["1"]}>
                            {content}
                        </Accordion>
                    </ul>
                </div>
                <div className="container mx-auto hidden overflow-hidden md:block">
                    <Img 
                        fluid={data.allServicesJson.nodes.find(s => s.id === String(selected)).image.childImageSharp.fluid}
                        style={{minHeight:'100%', maxHeight:'722px', width: '100%'}}
                        imgStyle={{objectFit:'cover'}}
                    />
                    {/* {JSON.stringify(data.allServicesJson.nodes.find(s => s.id == selected).image.childImageSharp.fluid)} */}
                </div>
            </section>
    )
}

const ServiceItemDescription = ({description}) => {
    return(
        <AccordionItemPanel className="md:pl-10 text-xl align-middle min-h-32 text-center md:text-left">
            {description}
        </AccordionItemPanel>
    )
}

const ServiceItemTitle = ({title}) => {
    const [hovered, setHovered] = useState(false);
    
    return(
        <AccordionItemHeading>
            <AccordionItemButton className="outline-none">
                <div className="md:flex md:items-center text-rp-black mx-auto sm:text-5xl text-2xl font-medium leading-relaxed outline-none"
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    role="button">
                        <span className="hidden md:flex h-8 w-8 mr-2">
                            <AccordionItemState>
                                {(state) =>
                                    {
                                    return(
                                        (hovered || state.expanded) && <ArrowRight size={32}/> 
                                    )}
                                }
                            </AccordionItemState>
                        </span>
                        <AccordionItemState>
                            {(state) =>
                            {
                                return(
                                    <li className="text-center md:text-left" style={{textDecoration: (hovered || state.expanded) && 'underline'}}>
                                        {title}
                                    </li>
                                )
                            }}
                        </AccordionItemState>
                </div>
            </AccordionItemButton>
        </AccordionItemHeading>
    )
}

const ServiceItem = ({title, description, id}) => {
    return(
        <AccordionItem uuid={id} className="flex flex-col pt-10">
            <ServiceItemTitle title={title}/>
            <ServiceItemDescription description={description} />
        </AccordionItem>
    )
}

export default Services;