import React, { useState, useEffect } from "react"
import classNames from "classnames"
import { useSpring, config} from "react-spring"
import SEO from "./seo"
import Disclaimer from "./disclaimer"
import Menu from "./menu.js"
// import Nav from "./nav"
import Helmet from 'react-helmet'


if (typeof window !== "undefined") {
        // eslint-disable-next-line global-require
        require("smooth-scroll")('a[href*="#"]')
    }

const Layout = ({children}) => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    }

    const menuAnimation = useSpring({
        config: {
            mass: 1,
            tension: 280,
            friction: 80
        },
        opacity: isMenuOpen ? 1 : 0,
        transform: isMenuOpen ? `translateX(0)` : `translateX(100%)`
    })
    
    const restrictScroll = classNames ({
        'overflow-hidden': isMenuOpen
    })

    let childrenWithProps = React.Children.map(children, child => React.cloneElement(child, { menuAnimation: menuAnimation, toggleMenu: toggleMenu, isMenuOpen: isMenuOpen}));

    return(
        <main className="h-full max-w-screen-2xl mx-auto">
            <Helmet>
                <body className={restrictScroll} />
            </Helmet>
            <SEO />
            <Menu className="menu-wrap" style={menuAnimation} toggleMenu={toggleMenu}/>
            <Disclaimer />
            <div className={`${restrictScroll} content-wrap h-full overflow-auto`}>
                    {childrenWithProps}
            </div>
        </main>
    )
}

export default Layout